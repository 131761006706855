import { createAsyncThunk } from '@reduxjs/toolkit';

import { GET } from 'src/utils/services';

const loadMore = createAsyncThunk('data/load-more', async (_arg, { rejectWithValue, getState }) => {
  try {
    const {
      data: { nextLink }
    } = getState();
    const resData = await GET(`${nextLink}`, false, true);
    return {
      list: resData.data || [],
      nextLink: resData.links?.next || ''
    };
  } catch (error) {
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

export { loadMore };
