import MobileDetect from 'mobile-detect';
import { useMediaQuery } from 'react-responsive';

export const useDesktop = () => useMediaQuery({ query: '(min-width: 1024px)' });
export const useMobile = () => useMediaQuery({ query: '(max-width: 1023px)' });

export const Desktop = ({ children }) => {
  const isDesktop = useDesktop();
  return isDesktop ? <div>{children}</div> : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMobile();
  return isMobile ? <div>{children}</div> : null;
};

export const getDevice = () => {
  if (typeof window !== 'undefined') {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    if (mobileDetect.phone()) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
  return null;
};

export const isRender = device => {
  return device === 'all' || device === getDevice();
};
