import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { timestamp } from 'src/utils/helper';
import { loadMore } from 'src/redux/data/thunk';

// entity
const dataAdapter = createEntityAdapter();

// initial state
const initialState = {
  nextLink: '',
  router: '',
  keyword: '',
  ...dataAdapter.getInitialState()
};

// slice
const dataSlice = createSlice({
  name: 'data',
  initialState: _.cloneDeep(initialState),
  reducers: {
    clear: state => {
      state.nextLink = '';
      state.router = '';
      state.keyword = '';
      state.ids = [];
      state.entities = {};
    },
    initialValue: (state, { payload }) => {
      const { list, router, nextLink, pageType, keyword } = payload;
      state.nextLink = list.length === 0 || !nextLink ? '' : nextLink;
      state.router = router || '';
      state.keyword = keyword || '';
      dataAdapter.addMany(state, getListEntity[pageType](list, router, state.ids.length, keyword));
    }
  },
  extraReducers: builder => {
    builder.addCase(loadMore.fulfilled, (state, { payload, meta }) => {
      const { router, keyword, ids } = state;
      const { list, nextLink } = payload;
      const { arg: pageType } = meta;
      state.nextLink = list.length === 0 ? '' : nextLink;
      dataAdapter.addMany(state, getListEntity[pageType](list, router, ids.length, keyword));
    });
    builder.addCase(loadMore.rejected, (state, { payload, error }) => {
      console.error(`${timestamp()}==========> LOAD_DATA ERROR : ${state.nextLink}`, payload || error.message);
    });
  }
});

// action
export const dataAction = dataSlice.actions;

// reducer
export default dataSlice.reducer;

// logic
const getListEntity = {
  search: (list, router, offset, keyword) =>
    _.map(list, (data, index) => ({
      id: data.id || _.uniqueId('data-'),
      data,
      eventDataLayer: {
        type: DATALAYER_TYPE.TRACK_INDEX,
        router,
        section: 'section-1',
        data: {
          title: data.title,
          heading: `SEARCH RESULT ${keyword}`,
          index: offset + index + 1
        }
      }
    })),
  category: (list, router, offset) =>
    _.map(list, (data, index) => ({
      id: data.id || _.uniqueId('data-'),
      data,
      eventDataLayer: {
        type: DATALAYER_TYPE.TRACK_INDEX,
        router,
        section: 'section-1',
        data: {
          title: data.title,
          heading: data.section?.th,
          block: '2',
          index: offset + index + 1
        }
      }
    })),
  tag: (list, router, offset, keyword) =>
    _.map(list, (data, index) => ({
      id: data.id || _.uniqueId('data-'),
      data,
      eventDataLayer: {
        type: DATALAYER_TYPE.TRACK_INDEX,
        router,
        section: 'section-1',
        data: {
          title: data.title,
          heading: `TAG RELATED ${keyword}`,
          index: offset + index + 1
        }
      }
    }))
};
