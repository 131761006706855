import parse from 'html-react-parser';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import PageError from 'pages/404';
import { useEffect } from 'react';
import { TiArrowUpThick } from 'react-icons/ti';
import { Provider } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/assets/sass/style.scss';
import { FILE_CLOSE, GTM_ID, TYPE_FILE_CLOSE } from 'src/constants';
import { store } from 'src/redux/store';
import { useDesktop } from 'src/utils/device';
import { dropScriptAdsHeader } from 'src/utils/helper';

const ScrollToTop = dynamic(import('react-scroll-to-top'));
const NextNProgress = dynamic(import('nextjs-progressbar'));

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  if (pageProps.statusCode >= 400) {
    return <PageError sessionSevId={null} />;
  }
  const router = useRouter();
  // const CheckElection = EMBED_ELECTION_ON_WEB !== '' ? `${router.pathname === '/election/thailand' && ELECTION_POLL_NEXT_PUBLIC_GTM_ID}` : GTM_ID;
  useEffect(() => {
    function removejscssfile(filename, filetype) {
      const targetelement = filetype === 'js' ? 'script' : filetype === 'css' ? 'link' : 'none'; // determine element type to create nodelist from
      const targetattr = filetype === 'js' ? 'src' : filetype === 'css' ? 'href' : 'none'; // determine corresponding attribute to test for
      const allsuspects = document.getElementsByTagName(targetelement);
      for (let i = allsuspects.length; i >= 0; i--) {
        // search backwards within nodelist for matching elements to remove
        if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]);
        } // remove element by calling parentNode.removeChild()
      }
    }

    removejscssfile(FILE_CLOSE, TYPE_FILE_CLOSE); // remove all occurences of "somescript.js" on page
  });

  return (
    <>
      <>
        <Head>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
          {!_.isEmpty(pageProps?.adsHeader) ? (
            <>
              {!useDesktop() && !_.isEmpty(pageProps?.ads) ? parse(`${pageProps?.adsHeader?.header_mobile}`) : dropScriptAdsHeader('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', '\r\n', pageProps?.adsHeader?.header_mobile)}
              {useDesktop() && !_.isEmpty(pageProps?.ads) ? parse(`${pageProps?.adsHeader?.header_desktop}`) : dropScriptAdsHeader('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', '\r\n', pageProps?.adsHeader?.header_desktop)}
            </>
          ) : (
            ''
          )}
          {/* -------------------------------- ADS : PPN ------------------------------- */}
          {router.pathname !== '/[cid]/[id]' && (
            <>
            <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
            <script
              type='text/javascript'
              dangerouslySetInnerHTML={{
                __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
              }}
              />
            </>
          )}
          {/* ------------------------- ADS : INTERSTITIALS ------------------------- */}
          {/* <script
            async
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: `
              window.googletag = window.googletag || {cmd: []};
              // GPT ad slots
              var interstitialSlot, staticSlot;

              googletag.cmd.push(function() {
                  // Define a web interstitial ad slot.
                  interstitialSlot = googletag.defineOutOfPageSlot(
                      '231599934,12270527/springnews/interstital-ads/interstital-ads',
                      googletag.enums.OutOfPageFormat.INTERSTITIAL);

                  // Slot returns null if the page or device does not support interstitials.
                  if (interstitialSlot) {
                      interstitialSlot.addService(googletag.pubads());
                      document.getElementById('status').innerText = 'Interstitial is loading...';

                      // Add event listener to enable navigation once the interstitial loads.
                      // If this event doesn't fire, try clearing local storage and refreshing
                      // the page.
                      googletag.pubads().addEventListener('slotOnload', function(event) {

                          if (interstitialSlot === event.slot) {
                              document.getElementById('link').style.display = 'block';
                              document.getElementById('status').innerText = 'Interstitial is loaded.';
                          }
                      });
                  }

                  // Define static ad slots.
                  staticSlot = googletag.defineSlot(
                          '231599934,12270527/springnews/interstital-ads/interstital-ads', [1, 1],
                          'static-ad-1')
                      .addService(googletag.pubads());

                  // Enable SRA and services.
                  googletag.pubads().enableSingleRequest();
                  googletag.enableServices();
              });`
            }}
          /> */}

          {/* --------------------------------- Truehit -------------------------------- */}
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                    (function() {
                      var ga1 = document.createElement('script'); 
                      ga1.type = 'text/javascript';
                      ga1.async = true;
                      ga1.src = "//lvs.truehits.in.th/dataa/t0030199.js";
                      var s = document.getElementsByTagName('script')[0]; 
                      s.parentNode.insertBefore(ga1, s);
                    })();
                    `
            }}
          />
        </Head>
        <NextNProgress color='#ffe600' startPosition={0.5} stopDelayMs={200} height={3} showOnShallow={true} />
        <ScrollToTop
          smooth
          color='#ffe600'
          component={
            <div className='scroll-to-top'>
              <TiArrowUpThick />
            </div>
          }
        />
        <Provider store={store}>
        <Script
          id='gtag-base'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer', '${GTM_ID}');
                      `
          }}
        />
          <Component {...pageProps} />
        </Provider>
      </>
    </>
  );
};

export default MyApp;
