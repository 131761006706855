import { BsTwitterX } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import { SiLine } from 'react-icons/si';
import { APP_IMG, APP_URL } from 'src/constants';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
export const WEB_NAME = 'springnews';
export const WEB_TITLE = 'ข่าววันนี้ ข่าวดัง ข่าวจริง สปริงนิวส์ » Spring News';
export const WEB_DESC =
  'สปริงนิวส์ สำนักข่าวออนไลน์ รายงานทุกข่าวด่วน ถ่ายทอดสด ทุกเหตุการณ์ Breaking News พร้อมมุมมองที่แตกต่าง ตรวจสอบข่าวจริง พิสูจน์ข่าวปลอมได้ที่นี่ www.springnews.co.th';
export const WEB_KEYWORD = 'Spring News, สปริงนิวส์, ข่าว, ข่าวด่วน, ข่าวใหม่, ข่าวล่าสุด, ข่าววันนี้, ข่าวดัง, ข่าวจริง, Breaking News';
export const WEB_LOGO = `${APP_IMG}/static/logo-black.png`;
export const THEME_WEB = '#000000';
export const LOGO_192 = 'Icon-192.png';
export const LOGO_FAVICON = 'favicon.ico';
export const LOGO_APPLE = 'logo-apple-touch.png';
export const IMAGE_DEFAULT = `${APP_IMG}/static/default.jpg`; // default image

export const TWITTER = {
  HANDLE: 'Spring News',
  CREATOR: '@SPRiNGNEWS_TH',
  SITE: '@SPRiNGNEWS_TH'
};

export const FACBOOK = {
  PAGE: '380377805483443'
};

/* -------------------------------------------------------------------------- */
/*                                   FOOTER                                   */
/* -------------------------------------------------------------------------- */
export const WEB_URL = {
  WEB: `${APP_URL}/`,
  FACEBOOK: 'https://www.facebook.com/SpringNewsonline',
  TWITTER: 'https://twitter.com/SPRiNGNEWS_TH',
  INSTAGRAM: 'https://www.instagram.com/springnews/',
  YOUTUBE: 'https://www.youtube.com/user/springnewsonline',
  LINE: 'https://line.me/R/ti/p/@springnews',
  TIKTOK: 'https://www.tiktok.com/@springonline'
};

export const ADDRESS_ORG = {
  CONTENT: 'ชั้น 7 เลขที่ 1854 ถ.เทพรัตน แขวงบางนาใต้ เขตบางนา',
  PROVINCE: 'กรุงเทพฯ',
  REGION: 'TH',
  POSTCODE: '10260',
  COUNTRY: 'THAILAND'
};

export const SOCIAL_LIST = [
  { id: 1, icon: <FaFacebookF id='facebook' className='--facebook' />, link: WEB_URL.FACEBOOK, name: 'facebook', className: 'icon-facebook' },
  { id: 2, icon: <BsTwitterX id='X' className='--X' />, link: WEB_URL.TWITTER, name: 'X', className: 'icon-X' },
  { id: 3, icon: <FaInstagram id='instagram' className='--instagram' />, link: WEB_URL.INSTAGRAM, name: 'instagram', className: 'icon-instagram' },
  { id: 4, icon: <FaYoutube id='youtube' className='--youtube' />, link: WEB_URL.YOUTUBE, name: 'youtube', className: 'icon-youtube' },
  { id: 5, icon: <SiLine id='line' className='--line' />, link: WEB_URL.LINE, name: 'line', className: 'icon-line' },
  { id: 6, icon: <FaTiktok id='tiktok' className='--tiktok' />, link: WEB_URL.TIKTOK, name: 'tiktok', className: 'icon-tiktok' }
];
